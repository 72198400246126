<template>
  <section id="additional-offer" class="additional-offer">
    <div class="container">
      <H2
        parent-class="additional-offer__title"
        v-if="content.title"
        data-aos="custom-appearance"
        data-aos-duration="800"
        >{{ content.title }}</H2
      >
      <Subtitle
        parent-class="additional-offer__description"
        v-if="content.desc"
        data-aos="custom-appearance"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        {{ content.desc }}
      </Subtitle>
      <ul class="additional-offer__list" v-if="content.list">
        <ItemAdditionalOffer
          v-for="(item, index) in content.list"
          :key="item"
          :content="item"
          :order="index"
          :popup="content.popup"
        />
      </ul>
    </div>
  </section>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import ItemAdditionalOffer from "@/components/block/ItemAdditionalOfferApp.vue";

export default {
  components: {
    H2,
    Subtitle,
    ItemAdditionalOffer,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.additional_offers,
    };
  },
};
</script>

<style>
.additional-offer {
  margin-bottom: 170px;
}

.additional-offer__title {
  margin-bottom: 10px;

  text-align: center;
}

.additional-offer__description {
  width: 450px;
  margin: 0 auto 70px auto;

  text-align: center;
}

.additional-offer__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
}

@media (max-width: 1599.98px) {
  .additional-offer {
    margin-bottom: 150px;
  }

  .additional-offer__description {
    width: 450px;
    margin-bottom: 60px;
  }
}

@media (max-width: 1199.98px) {
  .additional-offer {
    margin-bottom: 130px;
  }

  .additional-offer__description {
    width: 364px;
    margin-bottom: 55px;
  }

  .additional-offer__list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 50px 20px;
    grid-template-areas: ". .";
  }
}

@media (max-width: 991.98px) {
  .additional-offer {
    margin-bottom: 110px;
  }

  .additional-offer__description {
    margin-bottom: 50px;
  }

  .additional-offer__img {
    width: 88px;
    height: 88px;
  }
}

@media (max-width: 767.98px) {
  .additional-offer {
    margin-bottom: 90px;
  }

  .additional-offer__description {
    width: 290px;
    margin-bottom: 45px;
  }

  .additional-offer__list {
    gap: 22px 14px;
  }
}

@media (max-width: 532.98px) {
  .additional-offer__list {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}

</style>