<template>
  <div id="status-form" class="status-form">
    <div class="status-form__img">
      <svg
        class="status-form__svg"
        width="53"
        height="53"
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="26.5" cy="26.5" r="25" stroke="#3385D7" stroke-width="3" />
        <path
          d="M37 19L23.681 34L17 26.9412"
          stroke="#3385D7"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <H3 class="status-form__title" v-if="popup.title">{{ popup.title }}</H3>
    <Subtitle v-if="popup.desc">{{ popup.desc }} </Subtitle>
  </div>
</template>

<script>
import H3 from "@/components/element/H3App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    H3,
    Subtitle,
  },
  data() {
    return {
      popup: this.$store.state.data.contentResponse.popup_4,
    };
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#status-form",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateStatusFormPopop");
          },
        },
        dragToClose: false,
        autoFocus: false,
      }
    );

    setTimeout(() => {
      Fancybox.close();
    }, 2000);
  },
};
</script>

<style>
.status-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 406px;
  height: 310px;
  padding: 70px 60px;

  text-align: center;
}

.status-form__img {
  padding-top: 7px;
  margin-bottom: 30px;
}

.status-form__svg {
  display: block;
}

.status-form__title {
  margin-bottom: 20px;
}

#status-form .carousel__button.is-close {
  display: none;
}

@media screen and (max-width: 1599.98px) {
  .status-form {
    width: 380px;
    height: 290px;
    padding: 60px 50px;
  }
}

@media screen and (max-width: 1199.98px) {
  .status-form {
    width: 340px;
    height: 270px;
    padding: 50px 40px;
  }
}

@media screen and (max-width: 991.98px) {
  .status-form {
    width: 300px;
    height: 250px;
    padding: 40px 30px;
  }
}

@media screen and (max-width: 767.98px) {
  .status-form {
    width: 290px;
    height: 240px;
    padding: 30px 20px;
  }
}
</style>