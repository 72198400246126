<template>
  <section id="progress-work" class="progress-work">
    <div class="container">
      <H2 parent-class="progress-work__title" v-if="content.title">{{
        content.title
      }}</H2>
      <div class="progress-work__list-wrapper">
        <ul class="progress-work__list" v-if="content.list">
          <ItemProgressWork
            v-for="(item, index) in content.list"
            :key="item"
            :content="item"
            :number="index"
          />
        </ul>
        <div
          class="progress-work__line"
          data-aos="custom-work"
          data-aos-duration="1500"
          data-aos-easing="lineage"
          :data-aos-delay="650"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import ItemProgressWork from "@/components/block/ItemProgressWorkApp";
import H2 from "@/components/element/H2App.vue";

export default {
  components: {
    ItemProgressWork,
    H2,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.progress_work,
    };
  },
};
</script>

<style>
.progress-work {
  margin-bottom: 170px;
}

.progress-work__title {
  margin-bottom: 70px;

  text-align: center;
}

.progress-work__list-wrapper {
  position: relative;
  z-index: 0;
}

.progress-work__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
}

.progress-work__line {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;

  width: 100%;
  height: 72px;

  background-image: url("@/assets/img/progress-work-line.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1074px 72px;
}

[data-aos="custom-work"] {
  overflow: hidden;
  clip-path: inset(0 100% 0 0);
  transition: 1.2s cubic-bezier(0.4, 0.08, 0, 1.03);
  transition-property: clip-path;
}

[data-aos="custom-work"].aos-animate {
  clip-path: inset(0 0 0 0);
}

@media (max-width: 1599.98px) {
  .progress-work {
    margin-bottom: 150px;
  }

  .progress-work__title {
    margin-bottom: 60px;
  }

  .progress-work__list:after {
    height: 60px;

    background-size: 875px 60px;
  }

  .progress-work__line {
    background-size: 875px 60px;
  }
}

@media (max-width: 1199.98px) {
  .progress-work {
    margin-bottom: 130px;
  }

  .progress-work__title {
    margin-bottom: 55px;
  }

  .progress-work__line {
    background-size: 795px 54px;
  }
}

@media (max-width: 991.98px) {
  .progress-work {
    margin-bottom: 110px;
  }

  .progress-work__title {
    margin-bottom: 50px;
  }

  .progress-work__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px 0;
    grid-template-areas: ".";
    justify-items: center;
    width: 290px;
  }

  .progress-work__list-wrapper {
    width: 290px;
    margin: 0 auto;
  }

  .progress-work__line {
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;

    width: 38px;
    height: 100%;

    background-image: url("@/assets/img/progress-work-line-mob.svg");
    background-position: top;
    background-size: 38px 462px;
  }

  .card-progress-work {
    height: 130px;
  }

  [data-aos="custom-work"] {
    overflow: hidden;
    clip-path: inset(0 0 100% 0);
    transition: 1.2s cubic-bezier(0.4, 0.08, 0, 1.03);
  }

  [data-aos="custom-work"].aos-animate {
    clip-path: inset(0 0 0 0);
  }
}

@media (max-width: 767.98px) {
  .progress-work {
    margin-bottom: 90px;
  }
}
</style>