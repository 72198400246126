<template>
  <h2 :class="classObject">
    <slot />
  </h2>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        header2: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.header2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  color: #101010;
}

.header2_size_min {
  font-size: 36px;
  font-weight: 400;
  line-height: 170%;
}

.header2_color_white {
  color: #ffffff;
}

@media screen and (max-width: 1599.98px) {
  .header2 {
    font-size: 40px;
    line-height: 120%;
  }

  .header2_size_min {
    font-size: 30px;
    line-height: 150%;
  }
}

@media screen and (max-width: 1199.98px) {
  .header2 {
    font-size: 32px;
  }

  .header2_size_min {
    font-size: 24px;
  }
}

@media screen and (max-width: 991.98px) {
  .header2 {
    font-size: 28px;
  }

  .header2_size_min {
    font-size: 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .stocks__title {
    font-size: 26px;
  }

  .header2_size_min {
    font-size: 26px;
  }
}
</style>