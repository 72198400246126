<template>
  <section id="gallery" class="gallery">
    <div class="container" id="gallery-container">
      <div class="gallery__text">
        <H2 parent-class="gallery__title" v-if="content.title">{{
          content.title
        }}</H2>
        <Subtitle
          parent-class="gallery__description"
          v-if="content.desc"
          v-html="content.desc"
        >
        </Subtitle>
      </div>
    </div>
    <div class="gallery__swiper-wrapper">
      <div
        class="gallery__swiper swiper"
        data-aos="custom-riding"
        data-aos-duration="1000"
        data-aos-easing="linear"
        v-if="content.list"
      >
        <ul class="gallery__list swiper-wrapper">
          <ItemGallery
            swiper="true"
            v-for="item in content.list"
            :key="item"
            :content="item"
          />
        </ul>
        <div class="gallery__controls">
          <ButtonSlidePrev parent-class="gallery__prev" />
          <ButtonSlideNext parent-class="gallery__next" />
          <PaginationSlide parent-class="gallery__pagination" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import ItemGallery from "@/components/block/ItemGalleryApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    H2,
    Subtitle,
    ItemGallery,
    PaginationSlide,
    ButtonSlideNext,
    ButtonSlidePrev,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.gallery,
    };
  },
  mounted() {
    let marginElemGallery = null;

    if (window.innerWidth < 768) {
      marginElemGallery = 15;
    } else if (window.innerWidth < 992) {
      marginElemGallery = 20;
    } else {
      marginElemGallery = 30;
    }

    let offsetContainer = document
      .getElementById("container")
      .getBoundingClientRect();

    let oneElemGallery = document
      .querySelector(".card-gallery:nth-child(1)")
      .getBoundingClientRect();

      console.log(offsetContainer);
      console.log(oneElemGallery);

    let offset =
      oneElemGallery.width + marginElemGallery - offsetContainer.left;

      console.log(offset);

    let galleryWrapper = document.querySelector(".gallery__swiper-wrapper");
    let galleryNext = document.querySelector(".gallery__next");
    let galleryPrev = document.querySelector(".gallery__prev");
    let galleryPagination = document.querySelector(".gallery__pagination");

    galleryWrapper.style.marginLeft = "-" + offset + "px";
    galleryNext.style.transform = "translateX(" + offset / 2 + "px)";
    galleryPrev.style.transform = "translateX(" + offset / 2 + "px)";
    galleryPagination.style.transform = "translateX(" + offset / 2 + "px)";

    new Swiper(".gallery__swiper", {
      slidesPerView: "auto",
      loopedSlides: 20,
      maxBackfaceHiddenSlides: 20,
      modules: [Navigation, Pagination],
      loop: true,
      freeMode: true,
      speed: 500,
      navigation: {
        nextEl: ".gallery__next",
        prevEl: ".gallery__prev",
      },
      pagination: {
        el: ".gallery__pagination",
        type: "bullets",
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        767: {
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 30,
        },
      },
    });

    Fancybox.bind('[data-fancybox="card-gallery"]', {
      Thumbs: false,
      Toolbar: false,
      Image: {
        zoom: false,
        click: false,
      },
    });
  },
};
</script>

<style>
.gallery {
  padding-bottom: 170px;
}

.gallery__text {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}

.gallery__title {
  width: 450px;
  flex-grow: 0;
  margin-right: 270px;
}

.gallery__description {
  width: 450px;
  flex-grow: 0;
}

.gallery__wrapper {
  position: relative;
}

.gallery__swiper-wrapper {
  overflow: hidden;
}

.gallery__next,
.gallery__prev {
  position: absolute;
  top: calc(50% - 30px);
  z-index: 1;
}

.gallery__list {
  align-items: center;
}

.gallery__prev {
  left: calc(50% - 735px);
}

.gallery__next {
  right: calc(50% - 735px);
}

.gallery__pagination {
  display: none;
}

@media (max-width: 1599.98px) {
  .gallery {
    padding-bottom: 150px;
  }

  .gallery__text {
    margin-bottom: 66px;
  }

  .gallery__title {
    width: 338px;
    margin-right: 124px;
  }

  .gallery__description {
    width: 338px;
  }

  .gallery__next,
  .gallery__prev {
    top: calc(50% - 22px);
  }

  .gallery__prev {
    left: calc(50% - 562px);
  }

  .gallery__next {
    right: calc(50% - 562px);
  }
}

@media (max-width: 1199.98px) {
  .gallery {
    padding-bottom: 130px;
  }

  .gallery__text {
    margin-bottom: 55px;
  }

  .gallery__title {
    width: 377px;
    margin-right: 88px;
  }

  .gallery__description {
    width: 365px;
  }

  .gallery__next,
  .gallery__prev {
    top: calc(50% - 10px);
  }

  .gallery__prev {
    left: calc(50% - 503px);
  }

  .gallery__next {
    right: calc(50% - 503px);
  }
}

@media (max-width: 991.98px) {
  .gallery {
    padding-bottom: 110px;
  }

  .gallery__text {
    margin-bottom: 50px;
  }

  .gallery__title {
    width: 286px;
    margin-right: 20px;
  }

  .gallery__description {
    width: 286px;
  }

  .gallery__next,
  .gallery__prev {
    display: none;
  }

  .gallery__pagination {
    display: flex;
    width: 290px !important;
    margin: 35px auto 0 auto;
  }
}

@media (max-width: 767.98px) {
  .gallery {
    padding-bottom: 90px;
  }

  .gallery__text {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 45px;
  }

  .gallery__title {
    width: 290px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .gallery__description {
    width: 290px;
  }
}
</style>