<template>
  <div id="popup-question" class="popup-question" style="display: none">
    <div class="popup-question__title" v-if="popup.title">
      {{ popup.title }}
    </div>
    <Subtitle parent-class="popup-question__desc" v-if="popup.desc">{{
      popup.desc
    }}</Subtitle>
    <form class="popup-question__form" action="#">
      <p
        :class="
          errors.includes('your_name')
            ? 'popup-question__field error'
            : 'popup-question__field'
        "
      >
        <Label parent-class="popup-question__label">{{
          errors.includes("your_name") ? "* Поле “Имя” не указано" : "* Имя"
        }}</Label>
        <span class="input_wrapper">
          <InputText
            parent-class="popup-question__input"
            type="text"
            name="popup-question-name"
            :model-value="dataForm.your_name"
            @update:model-value="dataForm.your_name = $event"
          />
        </span>
      </p>
      <p
        :class="
          errors.includes('your_phone')
            ? 'popup-question__field error'
            : 'popup-question__field'
        "
      >
        <Label parent-class="popup-question__label">{{
          errors.includes("your_phone")
            ? "* Поле “Телефон” не указано"
            : "* Телефон"
        }}</Label>
        <span class="input_wrapper">
          <InputText
            parent-class="popup-question__input"
            type="text"
            name="popup-question-phone"
            v-input-mask
            :model-value="dataForm.your_phone"
            @update:model-value="dataForm.your_phone = $event"
          />
        </span>
      </p>
      <Button
        parent-class="popup-question__button"
        v-on:click="submitForm"
        v-if="popup.button"
        >{{ popup.button }}</Button
      >
      <p
        :class="
          errors.includes('your_politics')
            ? 'popup-question__politics error'
            : 'popup-question__politics'
        "
      >
        <Label class="popup-question__politics_label">
          <Checkbox
            parentClass="popup-question__politics-checkbox"
            :model-checked="dataForm.your_politics"
            @update:model-checked="dataForm.your_politics = $event"
          />
          <span class="popup-question__politics-text">{{
            errors.includes("your_politics")
              ? "Подтвердите согласие на обработку данных"
              : "Я согласен с персональной обработкой данных"
          }}</span>
        </Label>
      </p>
    </form>
  </div>
</template>

<script>
import Subtitle from "@/components/element/SubtitleApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import Checkbox from "@/components/element/CheckboxApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    Subtitle,
    Button,
    InputText,
    Checkbox,
    Label,
  },
  data() {
    return {
      popup: this.$store.state.questionPopup.data,
      dataForm: {
        id: 220,
        your_name: "",
        your_phone: "",
        your_politics: true,
      },
      errors: [],
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.errors = [];

      if (!this.dataForm.your_name) {
        this.errors.push("your_name");
      }

      if (!this.dataForm.your_phone) {
        this.errors.push("your_phone");
      }

      if (!this.dataForm.your_politics) {
        this.errors.push("your_politics");
      }

      if (this.errors.length == 0) {
        this.$store.dispatch("submitForm", this.dataForm);
        this.dataForm = {
          id: 220,
          your_name: "",
          your_phone: "",
          your_politics: true,
        };
      }
    },
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#popup-question",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateQuestionPopup", {
              status: false,
              data: null
            });
          },
        },
        dragToClose: false,
        autoFocus: false,
        template: {
          closeButton: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 0.700394 -0.713757 0.700394 20 -0.000244141)" fill="#929292"/><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 -0.700394 0.713757 0.700394 1.00003 0.999756)" fill="#929292"/></svg>',
        }
      }
    );
  },
};
</script>

<style>
.popup-question {
  width: 450px;
  padding: 80px 54px;

  background-color: #ffffff;
}

.popup-question__title {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 36px;
  line-height: 170%;
  color: #101010;

  text-align: center;
}

.popup-question__desc {
  margin-bottom: 40px;

  text-align: center;
}

.popup-question__label {
  margin-left: 15px;
  margin-bottom: 10px;
}

.popup-question__input {
  width: 100%;
  margin-bottom: 10px;
}

.popup-question__button {
  width: 100%;
  margin-bottom: 25px;
}

.popup-question__politics_label {
  display: flex;
  align-items: center;
}

.popup-question__politics-checkbox {
  margin-right: 15px;
}

.popup-question__politics-text {
  padding-top: 2px;

  font-size: 12px;

  line-height: 1.1;
  cursor: pointer;
}

.error .popup-question__politics-text {
  color: #d92641;
}

#popup-question .carousel__button.is-close {
  top: 5px;
  right: 5px;
}

#popup-question .carousel__button svg {
  width: auto; 
  height: auto; 
  stroke: none; 
  filter: none; 
  stroke-width: 1px;
  stroke-linejoin: bevel;
  stroke-linecap: round;
}

#popup-question .carousel__button svg rect {
  will-change: transform;
}

@media (max-width: 1599.98px) {
  .popup-question__title {
    font-size: 34px;
  }

  .popup-question__desc {
    margin-bottom: 35px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }

  .popup-question__politics-checkbox {
    margin-right: 10px;
  }

  .popup-question__politics-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 1199px) {
  .popup-question {
    width: 400px;
    padding: 50px 40px;
  }

  .popup-question__title {
    font-size: 32px;
  }

  .popup-question__desc {
    margin-bottom: 30px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .popup-question {
    width: 340px;
    padding: 40px 30px;
  }

  .popup-question__title {
    font-size: 26px;
  }

  .popup-question__desc {
    margin-bottom: 30px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .popup-question {
    width: 290px;
    height: 385px;
    padding: 40px 20px;
  }

  .popup-question__title {
    font-size: 24px;
  }

  .popup-question__desc {
    margin-bottom: 20px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>