<template>
  <div class="top-button" v-scroll-to="'#top'">
    <svg class="top-button__icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 8L8 1L0.5 8" stroke="white" stroke-linecap="round"/>
      <rect x="7.5" y="16" width="15" height="1" rx="0.5" transform="rotate(-90 7.5 16)" fill="#FFF9F9"/>
    </svg>
  </div>
</template>
<script>
export default {
  mounted() {
    let topButtom = document.querySelector('.top-button');

    window.addEventListener('scroll', () => {
      let currentScroll = document.documentElement.scrollTop;

      if (currentScroll > 400) {
        topButtom.classList.add('show');
      } else {
        topButtom.classList.remove('show');
      }
    })
  }
};
</script>
<style>
.top-button {
  position: fixed;
  bottom: 145px;
  right: 84px;
  z-index: 998;

  display:flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  background-color: rgba(60, 67, 237, 0.6);
  backdrop-filter: blur(0px);
  cursor: pointer;
  opacity: 0;
  transform: scale3d(0, 0, 0);
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
}

.top-button.show {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.top-button__icon {
  width: 15px;
  height: 15px;
}

@media (max-width: 1919.98px) {
  .top-button {
    bottom: 145px;
    right: 35px;
  }
}

@media (max-width: 1199.98px) {
  .top-button {
    right: 30px;

    width: 50px;
    height: 50px;
  }

  .top-button__icon {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 991.98px) {
  .top-button {
    bottom: 100px;
  }
}

@media (max-width: 767.98px) {
  .top-button {
    right: 20px;
  }
}
</style>