<template>
  <div id="popup-services" class="popup-services" style="display: none">
    <div class="popup-services__left" :style="{backgroundImage: 'url(' + $mediaWp(content.id_img_bg, 'services') + ')',}">
      <img
        class="popup-services__img"
        :src="$mediaWp(content.id_img, 'services')"
        alt=""
        v-if="content.id_img"
      />
    </div>
    <div class="popup-services__right">
      <div class="popup-services__title" v-if="content.name">
        {{ content.name }}
      </div>
      <Subtitle parent-class="popup-services____desc" v-if="content.desc">
        {{ content.desc }}
      </Subtitle>
      <div class="popup-services__price">
        <span class="popup-services__price-main" v-if="content.price"
          >{{ content.price }} ₽</span
        >
        <span class="popup-services__price-old" v-if="content.price_old"
          >{{ content.price_old }} ₽</span
        >
      </div>
      <form class="popup-services__form" action="#">
        <p
          :class="
            errors.includes('your_phone')
              ? 'popup-services__field error'
              : 'popup-services__field'
          "
        >
          <Label parent-class="popup-services__label">{{
            errors.includes("your_phone")
              ? "* Поле “Телефон” не указано"
              : "* Телефон"
          }}</Label>
          <span class="input_wrapper">
            <InputText
              parent-class="popup-services__input"
              type="text"
              name="popup-services-phone"
              v-input-mask
              :model-value="dataForm.your_phone"
              @update:model-value="dataForm.your_phone = $event"
            />
          </span>
        </p>
        <Button
          parent-class="popup-services__button"
          v-on:click="submitForm"
          v-if="popup.buttom"
          >{{ popup.buttom }}</Button
        >
        <p
          :class="
            errors.includes('your_politics')
              ? 'popup-services__politics error'
              : 'popup-services__politics'
          "
        >
          <Label class="popup-services__politics_label">
            <Checkbox
              parentClass="popup-services__politics-checkbox"
              :model-checked="dataForm.your_politics"
              @update:model-checked="dataForm.your_politics = $event"
            />
            <span class="popup-services__politics-text">{{
              errors.includes("your_politics")
                ? "Подтвердите согласие на обработку данных"
                : "Я согласен с персональной обработкой данных"
            }}</span>
          </Label>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import Subtitle from "@/components/element/SubtitleApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import Checkbox from "@/components/element/CheckboxApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    Subtitle,
    Button,
    InputText,
    Checkbox,
    Label,
  },
  data() {
    return {
      content: this.$store.state.servicesPopup.data,
      popup: this.$store.state.data.contentResponse.popup_1,
      dataForm: {
        id: 562,
        your_name: this.$store.state.servicesPopup.data.name,
        your_phone: "",
        your_politics: true,
      },
      errors: [],
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.errors = [];

      if (!this.dataForm.your_phone) {
        this.errors.push("your_phone");
      }

      if (!this.dataForm.your_politics) {
        this.errors.push("your_politics");
      }

      if (this.errors.length == 0) {
        this.$store.dispatch("submitForm", this.dataForm);
        this.dataForm = {
          id: 562,
          your_phone: "",
          your_politics: true,
        };
      }
    },
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#popup-services",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateServicesPopup", {
              status: false,
              data: null,
            });
          },
        },
        dragToClose: false,
        autoFocus: false,
        template: {
          closeButton: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 0.700394 -0.713757 0.700394 20 -0.000244141)" fill="#929292"/><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 -0.700394 0.713757 0.700394 1.00003 0.999756)" fill="#929292"/></svg>',
        }
      }
    );
  },
};
</script>

<style>
.popup-services {
  display: flex;
  flex-direction: row;
  width: 798px;
  height: 537px;
  padding: 0;
}

.popup-services__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-shrink: 0;

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.popup-services__img {
  width: 192px;
  height: 128px;

  object-fit: cover;
  object-position: center;
}

.popup-services__right {
  width: 50%;
  flex-shrink: 0;
  padding: 70px 36px;
}

.popup-services__title {
  margin-bottom: 25px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-services____desc {
  height: 84px;
  margin-bottom: 25px;
}

.popup-services__price {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.popup-services__price-main {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}

.popup-services__price-old {
  position: relative;

  margin-left: 20px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #929292;
}

.popup-services__price-old:after {
  content: "";

  position: absolute;
  top: 9px;
  left: 0;

  width: 100%;
  height: 1px;

  background-color: #929292;
}

.popup-services__input {
  margin-bottom: 10px;
}

.popup-services__button {
  width: 100%;
  margin-bottom: 25px;
}

.popup-services__politics_label {
  display: flex;
  align-items: center;
}

.popup-services__politics-checkbox {
  margin-right: 15px;
}

.popup-services__politics-text {
  padding-top: 3px;

  font-size: 12px;

  line-height: 1.1;
  cursor: pointer;
}

.error .popup-services__politics-text {
  color: #d92641;
}

#popup-services .carousel__button.is-close {
  top: 8px;
  right: 8px;
}

#popup-services .carousel__button svg {
  width: auto; 
  height: auto; 
  stroke: none; 
  filter: none; 
  stroke-width: 1px;
  stroke-linejoin: bevel;
  stroke-linecap: round;
}

#popup-services .carousel__button svg rect {
  will-change: transform;
}

@media screen and (max-width: 1199.98px) {
  .popup-services {
    width: 698px;
    height: 437px;
  }
  .popup-services__right {
    padding: 50px 26px;
  }

  .popup-services__title {
    margin-bottom: 15px;
  }

  .popup-services____desc {
    margin-bottom: 15px;
  }

  .popup-services__price {
    margin-bottom: 15px;
  }

  .popup-services__price-main {
    font-size: 18px;
  }

  .popup-services__button {
    width: 100%;
    margin-bottom: 20px;
  }

  .popup-services__politics-checkbox {
    margin-right: 10px;
  }

  .popup-services__politics-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 991.98px) {
  .popup-services {
    width: 620px;
    height: 400px;
  }

  .popup-services__title {
    margin-bottom: 15px;
  }

  .popup-services____desc {
    height: 65px;
  }

  .popup-services__price {
    margin-bottom: 15px;
  }

  .popup-services__price-main {
    font-size: 18px;
  }

  .popup-services__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .popup-services {
    width: 500px;
    height: 400px;
  }
}

@media screen and (max-width: 532.98px) {
  .popup-services {
    display: flex;
    flex-direction: column;
    width: 290px;
    height: auto;
    padding: 0;
  }

  .popup-services__left {
    width: 100%;
    height: 270px;
  }

  .popup-services__img {
    object-position: top center;
  }

  .popup-services__right {
    width: 100%;
    padding: 20px 20px 30px 20px;
  }

  .popup-services__title {
    margin-bottom: 8px;
  }

  .popup-services____desc {
    height: 40px;
    margin-bottom: 15px;
  }

  .popup-services__price {
    margin-bottom: 10px;
  }

  .popup-services__button {
    margin-bottom: 15px;
  }
}
</style>