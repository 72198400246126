<template>
  <input
    :class="classObject"
    :id="name"
    :name="name"
    :type="type"
    :v-input-mask="vInputMask"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: ["parentClass", "name", "type", "vInputMask", "modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      classObject: {
        ["input-text"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.input-text {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border-radius: 50px;
  border: 1px solid #d5d5d5;
  background-color: transparent;

  -webkit-appearance: none;
  outline: none !important;
  transition: border-color 0.5s ease-in;
  box-shadow: none;
}

.input-text_color_blue {
  border-color: #5d7aac;
  color: #5d7aac;
}

.input-text:focus,
.input-text:hover {
  border-color: #969696;
  outline: none;
}

.error .input-text,
.error .input-text:focus,
.error .input-text:hover {
  border: 1px solid #d92641;
  transition-duration: 0s;
}

.error .input_wrapper {
  position: relative;
}

.error .input_wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;

  width: 22px;
  height: 22px;

  background-image: url("@/assets/img/error.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transform: translateY(-50%);
}

@media screen and (max-width: 1599.98px) {
  .input-text {
    padding: 9px;
  }
}

@media screen and (max-width: 1199.98px) {
  .input-text {
    padding: 7px;
  }

  .error .input_wrapper::after {
    width: 17px;
    height: 17px;
  }
}
</style>