<template>
  <div class="preloader">
    <div class="loading-text">Загрузка...</div>
    <div class="loading-indicator">
      <div class="background-indicator"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.preloader {
  display: flex;
  z-index: 99999999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #002056;
}

.loading-text {
  margin-bottom: 15px;
  color: #d9d9d9;
}

.loading-indicator {
  position: relative;
  width: 300px;
  height: 2px;
  margin-bottom: 40px;
  border-radius: 3px;
  background-color: #d4d4d4;
  overflow: hidden;
}

.background-indicator {
  width: 320px;
  height: 2px;
  border-radius: 3px;
  background-color: #2e2d7c;
  animation: indicator 1.5s infinite ease-in-out;
}

.loading-indicator::before {
  content: "";
  position: absolute;
  width: 320px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3px;
  background-color: #2e2d7c;
  animation: indicator 1.5s infinite ease-in-out;
}

.loading-indicator::after {
  content: "";
  position: absolute;
  width: 320px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3px;
  background-color: #3c43ed;
  animation: indicator 1.5s infinite ease-in-out 0.2s;
}

@keyframes indicator {
  from {
    transform: translateX(-320px);
  }
  to {
    transform: translateX(10px);
  }
}
</style>