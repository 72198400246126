<template>
  <section id="advantages" class="advantages">
    <div class="container">
      <H2
        parent-class="advantages__title"
        v-if="content.title"
        data-aos="custom-appearance"
        data-aos-duration="1000"
        >{{ content.title }}</H2
      >
      <Subtitle
        parent-class="advantages__description"
        v-if="content.desc"
        data-aos="custom-appearance"
        data-aos-duration="1000"
        data-aos-delay="100"
        >{{ content.desc }}</Subtitle
      >
      <ul class="advantages__list" v-if="content.list">
        <ItemAdvantages
          :content="item"
          :order="index + 1"
          v-for="(item, index) in content.list"
          :key="item"
        />
      </ul>
    </div>
  </section>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import Subtitle from "@/components/element/SubtitleApp.vue";
import ItemAdvantages from "@/components/block/ItemAdvantagesApp.vue";
export default {
  components: {
    H2,
    Subtitle,
    ItemAdvantages,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.advantages,
    };
  },
};
</script>

<style>
.advantages {
  margin-bottom: 170px;
}

.advantages__title {
  margin-bottom: 10px;

  text-align: center;
}

.advantages__description {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;

  text-align: center;
}

.advantages__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
}

@media (max-width: 1599.98px) {
  .advantages {
    margin-bottom: 150px;
  }

  .advantages__description {
    max-width: 420px;
    margin-bottom: 50px;
  }
}

@media (max-width: 1199.98px) {
  .advantages {
    margin-bottom: 130px;
  }

  .advantages__description {
    max-width: 365px;
    margin-bottom: 45px;
  }
}

@media (max-width: 991.98px) {
  .advantages {
    margin-bottom: 110px;
  }

  .advantages__description {
    margin-bottom: 40px;
  }

  .advantages__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 40px 20px;
    grid-template-areas: ". .";
  }
}

@media (max-width: 767.98px) {
  .advantages {
    margin-bottom: 90px;
  }

  .advantages__description {
    width: 100%;
    margin-bottom: 35px;
  }

  .advantages__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 45px 20px;
    grid-template-areas: ".";
  }
}
</style>