<template>
  <Preloader v-if="this.$store.state.preloader" />
  <IndexPage v-else />
</template>

<script>
import Preloader from "@/components/section/PreloaderApp.vue";
import IndexPage from "@/views/IndexPage.vue";
import AOS from "aos";

export default {
  name: "App",
  components: {
    Preloader,
    IndexPage,
  },
  mounted() {
    this.$store.dispatch("loadingData");

    AOS.init({
      once: true,
    });
  },
};
</script>

<style>
</style>
