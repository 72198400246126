<template>
  <span :class="classObject">
    <input
      class="checkbox visually-hidden"
      type="checkbox"
      :checked="modelChecked"
      @change="$emit('update:modelChecked', $event.target.checked)"
    />
    <span class="custom-checkbox"></span>
  </span>
</template>

<script>
export default {
  props: ["parentClass", "modelChecked"],
  emits: ["update:modelChecked"],
  data() {
    return {
      classObject: {
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.custom-checkbox {
  position: relative;

  flex-shrink: 0;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #3c43ed;
  border-radius: 4px;
  background-clip: padding-box;

  transition: background-color 0.5s linear, border-color 0.5s linear;
  cursor: pointer;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);

  width: 0;
  height: 12px;

  background-image: url(@/assets/img/checkbox.svg);
  background-repeat: no-repeat;
  transition: width 0.5s linear;
}

.checkbox:checked ~ .custom-checkbox {
  background-color: rgba(60, 67, 237, 0.3);
  border: 1px solid rgba(60, 67, 237, 0.3);
}

.checkbox_color_white .checkbox:checked ~ .custom-checkbox {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.checkbox:checked ~ .custom-checkbox::before {
  width: 12px;
}

@media screen and (max-width: 1599.98px) {
  .custom-checkbox {
    width: 15px;
    height: 15px;
  }

  .custom-checkbox::before {
    top: calc(50% - 4.5px);
    left: calc(50% - 4.5px);

    height: 9px;

    background-image: url(@/assets/img/checkbox_1599.svg);
  }

  .checkbox:checked ~ .custom-checkbox::before {
    width: 9px;
  }
}
</style>