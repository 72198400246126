<template>
  <footer class="footer">
    <div class="container footer__container">
      <div
        class="footer__сopyright"
        v-html="content.copyright"
        v-if="content.copyright"
      ></div>
      <div class="footer__contact">
        <Social
          parent-class="footer__social"
          :content="content.social"
          v-if="content.social"
        />
        <div class="footer__work" v-if="content.work_schedule">
          <div
            class="footer__work-item"
            v-for="item in content.work_schedule"
            :key="item"
          >
            <span class="footer__work-day">{{ item.days_week }}</span
            >{{ item.working_hours }}
          </div>
        </div>
        <a
          class="footer__mail"
          :href="'mailto:' + content.mail"
          v-if="content.mail"
          >{{ content.mail }}</a
        >
      </div>
      <div class="footer__dev">
        <div class="footer__dev-logo">
          <DevLogo />
        </div>
        <div class="footer__dev-text">
          Разработано<br />
          в
          <a class="footer__dev-link" href="https://stebnev-studio.ru/"
            >Stebnev Studio</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Social from "@/components/element/SocialApp.vue";
import DevLogo from "@/components/element/DevLogo.vue";

export default {
  components: {
    Social,
    DevLogo,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.footer,
    };
  },
};
</script>

<style>
.footer {
  width: 100%;
  background-color: #002056;
}

.footer__container {
  display: flex;
  align-items: center;
  padding-top: 37px;
  padding-bottom: 37px;
}

.footer__сopyright {
  max-width: 210px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #ffffff;
}

.footer__contact {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 208px;
  padding-right: 50px;
}

.footer__social {
  margin-right: 107px;
}

.footer__work {
  display: flex;
  flex-direction: column;
  margin-right: 84px;
}

.footer__work-item {
  margin-bottom: 3px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
}

.footer__work-item:last-child {
  margin-bottom: 0;
}

.footer__work-day {
  margin-right: 20px;
}

.footer__mail {
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  color: #ffffff;
}

.footer__mail::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: #ffffff;
  transition: width 0.5s ease-in;
}

.footer__mail:hover::after {
  width: 100%;
}

.footer__dev {
  display: flex;
  align-items: center;
  max-width: 160px;
}

.footer__dev-logo {
  margin-right: 18px;
}

.footer__dev-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #ffffff;
}

.footer__dev-link {
  position: relative;

  text-decoration: none;
  color: #ffffff;
}

.footer__dev-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 100%;
  height: 1px;

  background-color: #ffffff;
  transition: width 0.5s ease-in;
}

.footer__dev-link:hover::after {
  width: 0;
}

@media (max-width: 1599.98px) {
  .footer__container {
    padding-top: 33px;
    padding-bottom: 33px;
  }

  .footer__сopyright {
    max-width: 155px;

    font-size: 10px;
  }

  .footer__contact {
    padding-left: 120px;
    padding-right: 40px;
  }

  .footer__social {
    margin-right: 70px;
  }

  .footer__work {
    margin-right: 45px;
  }

  .footer__work-item {
    font-size: 14px;
  }

  .footer__work-day {
    margin-right: 20px;
  }

  .footer__mail {
    font-size: 14px;
  }

  .footer__dev {
    display: flex;
    align-items: center;
    max-width: 130px;
  }

  .footer__dev-logo {
    margin-right: 10px;
  }

  .footer__dev-text {
    font-size: 10px;
  }
}

@media (max-width: 1199.98px) {
  .footer__container {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .footer__сopyright {
    max-width: 135px;
  }

  .footer__contact {
    justify-content: space-between;
    padding-left: 195px;
    padding-right: 135px;
  }

  .footer__social {
    margin-right: 0;
  }

  .footer__work {
    margin-right: 0;
  }

  .footer__work-item {
    font-size: 12px;
  }

  .footer__work-day {
    margin-right: 30px;
  }

  .footer__mail {
    display: none;
  }

  .footer__dev {
    max-width: 130px;
  }

  .footer__dev-text {
    font-size: 10px;
  }
}

@media (max-width: 991.98px) {
  .footer__container {
    padding-top: 10px;
    padding-bottom: 60px;
  }

  .footer__сopyright {
    max-width: 135px;

    font-size: 9px;
  }

  .footer__contact {
    justify-content: flex-start;
    padding-left: 55px;
    padding-right: 20px;
  }

  .footer__social {
    margin-right: 35px;
  }

  .footer__work-item {
    font-size: 10px;
  }

  .footer__work-day {
    margin-right: 15px;
  }

  .footer__dev {
    max-width: 120px;
  }

  .footer__dev-text {
    font-size: 9px;
  }
}

@media (max-width: 767.98px) {
  .footer__container {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 65px;
  }

  .footer__сopyright {
    order: 2;
    width: 49%;
    max-width: none;
  }

  .footer__contact {
    order: 1;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 16px;
  }

  .footer__social {
    width: 49%;
    margin: 0;
  }

  .footer__work {
    width: 49%;
    margin: 0;
  }

  .footer__dev {
    order: 3;
    width: 49%;
    max-width: none;
    padding-left: 7px;
  }
}
</style>