<template>
  <li
    :class="classObject"
    data-aos="custom-appearance"
    data-aos-duration="1000"
    :data-aos-delay="order + '00'"
  >
    <img
      class="card-advantages__img"
      :src="$mediaWp(content.id_img, 'advantages')"
      alt=""
      v-if="content.id_img"
    />
    <H3 parent-class="card-advantages__name" v-if="content.title">{{
      content.title
    }}</H3>
    <p class="card-advantages__text" v-if="content.desc">{{ content.desc }}</p>
  </li>
</template>

<script>
import H3 from "@/components/element/H3App.vue";
export default {
  components: {
    H3,
  },
  props: ["parentClass", "content", "order"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["card-advantages"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.card-advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  text-align: center;
}

.card-advantages__img {
  width: 90px;
  height: 90px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.card-advantages__name {
  margin-bottom: 20px;
}

.card-advantages__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

@media (max-width: 1599.98px) {
  .card-advantages__img {
    width: 70px;
    height: 70px;
    margin-bottom: 25px;
  }

  .card-advantages__name {
    margin-bottom: 15px;
  }

  .card-advantages__text {
    font-size: 12px;
    line-height: 160%;
  }
}

@media (max-width: 1199.98px) {
  .card-advantages__img {
    width: 62px;
    height: 62px;
    margin-bottom: 22px;
  }

  .card-advantages__name {
    margin-bottom: 11px;
  }
}
</style>