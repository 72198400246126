<template>
  <li
    :class="classObject"
    data-aos="custom-appearance"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    :data-aos-delay="order * 150"
  >
    <img
      class="card-additional-offer__img"
      :src="$mediaWp(content.id_img, 'additional_offers')"
      alt=""
    />
    <H3 parent-class="card-additional-offer__name" v-if="content.name">{{
      content.name
    }}</H3>
    <p class="card-additional-offer__text" v-if="content.desc">
      {{ content.desc }}
    </p>
    <Button
      parent-class="card-additional-offer__button"
      v-on:click="openPopup()"
      v-if="content.button"
      >{{ content.button }}</Button
    >
  </li>
</template>
<script>
import H3 from "@/components/element/H3App.vue";
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    H3,
    Button,
  },
  props: ["parentClass", "swiper", "content", "order", "popup"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["card-additional-offer"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
  methods: {
    openPopup() {
      this.$store.commit("updateOrderPopup", {
        status: true,
        data: this.popup
      });
    },
  },
};
</script>
<style>
.card-additional-offer {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  text-align: center;

  background-color: #e9eaf6;
}

.card-additional-offer__img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;

  object-fit: cover;
  object-position: center;
}

.card-additional-offer__name {
  margin-bottom: 20px;
}

.card-additional-offer__text {
  flex-grow: 1;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #929292;

  opacity: 1;
  transition: opacity 0.3s linear 0.2s;
}

.card-additional-offer:hover .card-additional-offer__text {
  opacity: 0;
  transition-delay: 0s;
}

.card-additional-offer__button {
  position: absolute;
  bottom: 50px;

  opacity: 0;
  transition: opacity 0.3s linear;
}

.card-additional-offer:hover .card-additional-offer__button {
  opacity: 1;
  transition-delay: 0.4s;
}

@media (max-width: 1599.98px) {
  .card-additional-offer {
    padding: 30px 20px 20px 20px;
  }

  .card-additional-offer__img {
    width: 75px;
    height: 75px;
    margin-bottom: 15px;
  }

  .card-additional-offer__name {
    margin-bottom: 20px;
  }

  .card-additional-offer__text {
    font-size: 12px;
  }
}

@media (max-width: 1199.98px) {
  .card-additional-offer {
    padding: 25px 15px;
  }

  .card-additional-offer:hover .card-additional-offer__text {
    display: block;
  }

  .card-additional-offer__img {
    width: 68px;
    height: 68px;
  }

  .card-additional-offer__name {
    margin-bottom: 15px;
  }

  .card-additional-offer__text {
    opacity: 1;
    margin-bottom: 15px;
  }

  .card-additional-offer__button {
    position: relative;
    bottom: 0;

    display: flex;
  }

  .card-additional-offer:hover .card-additional-offer__text {
    opacity: 1;
  }

  .card-additional-offer__button {
    opacity: 1;
  }
}

@media (max-width: 767.98px) {
  .card-additional-offer {
    padding: 20px 10px;
  }

  .card-additional-offer__img {
    width: 42px;
    height: 42px;
  }

  .card-additional-offer__name {
    margin-bottom: 10px;
  }
}
</style>