<template>
  <section class="offer">
    <h2 class="visually-hidden">Торговое предложение</h2>
    <div
      class="offer__content"
      :style="{ backgroundImage: 'url(' + $mediaWp(content.id_img) + ')' }"
    >
      <div class="container">
        <div
          class="offer__title"
          v-if="content.title"
          data-aos="custom-appearance"
          data-aos-duration="1000"
        >
          {{ content.title }}
        </div>
        <div
          class="offer__description"
          v-if="content.desc"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          {{ content.desc }}
        </div>
        <div
          class="offer__button-wrapper"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <Button parent-class="offer__button" v-scroll-to="'#services'"
            >Подробнее</Button
          >
        </div>
      </div>
    </div>
    <div
      class="offer__video"
      v-if="content.id_poster || content.video"
      data-aos="custom-img"
      data-aos-duration="1200"
      data-aos-delay="1000"
    >
      <a
        class="offer__video-link"
        v-bind:href="content.video_link"
        data-fancybox="video"
      >
        <video
          class="offer__video-file"
          muted="muted"
          loop="loop"
          :poster="$mediaWp(content.id_poster)"
        >
          <source :src="$mediaWp(content.video)" type="video/mp4" />
        </video>
        <span class="offer__play">
          <span class="offer__play-button"></span>
        </span>
      </a>
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    Button,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer,
    };
  },
  mounted() {
    // Воспроизведение видео после анимации появления
    let animationElement = document.querySelector(".offer__video");

    animationElement.addEventListener("transitionend", function () {
      let videoElement = document.querySelector(".offer__video-file");
      let videoPlay = document.querySelector(".offer__play");

      videoElement.play();
      videoPlay.classList.add("offer__play_show");
    });

    // Инициализация Fancybox
    Fancybox.bind("data-fancybox='video'");
  },
};
</script>

<style>
.offer {
  width: 100%;
  padding-top: 105px;
  margin: 0 auto;
  margin-bottom: 175px;

  background-color: #ececec;
}

.offer__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 588px;
  padding-top: 40px;
  padding-bottom: 40px;

  background-color: #ececec;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  overflow: hidden;
}

.offer__title {
  margin-bottom: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 110%;
  color: #101010;
}

.offer__description {
  max-width: 565px;
  width: 100%;
  margin-left: 240px;
  margin-bottom: 30px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 170%;
  color: #101010;
}

.offer__button {
  margin-left: 240px;
}

.offer__video {
  position: relative;

  width: 100%;
  height: 665px;

  cursor: pointer;
}

.offer__video-file {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer__play {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;

  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.4s ease-in 0.4s;
}

.offer__play_show {
  opacity: 1;
}

.offer__play::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background-color: rgba(221, 221, 221, 0.4);
  animation: pulse-play 1500ms ease-out 3s infinite;
}

@keyframes pulse-play {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.offer__play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.5s ease-in;
}

.offer__video:hover .offer__play-button {
  background-color: rgba(124, 124, 124, 0.4);
}

.offer__play-button::before {
  content: "";

  width: 15px;
  height: 20px;
  margin-left: 3px;

  background-image: url("@/assets/img/play.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1919.98px) {
  .offer__content {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .offer__title {
    font-size: 64px;
  }
}

@media (max-width: 1599.98px) {
  .offer {
    padding-top: 71px;
    margin-bottom: 150px;
  }

  .offer__content {
    height: 435px;
    padding-top: 64px;
    padding-bottom: 30px;
  }

  .offer__title {
    margin-bottom: 30px;

    font-size: 48px;
  }

  .offer__description {
    max-width: 475px;
    margin-left: 185px;
    margin-bottom: 25px;

    font-size: 18px;
    line-height: 160%;
  }

  .offer__button {
    margin-left: 185px;
  }

  .offer__video {
    height: 465px;
  }

  .offer__play {
    width: 90px;
    height: 90px;
  }

  .offer__play-button {
    width: 60px;
    height: 60px;
  }

  .offer__play-button::before {
    width: 11px;
    height: 14px;
  }
}

@media (max-width: 1199.98px) {
  .offer {
    margin-bottom: 130px;
  }

  .offer__content {
    height: 344px;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .offer__title {
    margin-bottom: 20px;

    font-size: 36px;
  }

  .offer__description {
    max-width: 403px;
    margin-left: 165px;
    margin-bottom: 20px;

    font-size: 16px;
  }

  .offer__button {
    margin-left: 165px;
  }

  .offer__play {
    width: 70px;
    height: 70px;
  }

  .offer__play-button {
    width: 55px;
    height: 55px;
  }

  .offer__play-button::before {
    width: 9px;
    height: 12px;
  }
}

@media (max-width: 991.98px) {
  .offer {
    padding-top: 60px;
    margin-bottom: 110px;
  }

  .offer__content {
    height: 350px;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .offer__title {
    margin-bottom: 25px;

    font-size: 32px;
  }

  .offer__description {
    max-width: 388px;
    margin-left: 0;
    margin-bottom: 20px;

    font-size: 14px;
  }

  .offer__button {
    margin-left: 0;
  }

  .offer__video {
    height: 430px;
  }

  .offer__play {
    width: 60px;
    height: 60px;
  }

  .offer__play-button {
    width: 50px;
    height: 50px;
  }

  .offer__play-button::before {
    width: 6px;
    height: 8px;
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .offer {
    padding-top: 60px;
    margin-bottom: 70px;
  }

  .offer__content {
    height: 350px;
    padding-top: 70px;
    padding-bottom: 10px;
  }

  .offer__title {
    margin-bottom: 20px;

    font-size: 28px;
  }

  .offer__description {
    max-width: none;
  }

  .offer__button {
    margin-left: 0;
  }

  .offer__video {
    height: 250px;
  }
}
</style>