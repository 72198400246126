<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        "user-content": true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.user-content p {
  margin-bottom: 25px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #929292;
}

.user-content p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1599.98px) {
  .user-content p {
    margin-bottom: 23px;

    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .user-content p {
    font-size: 12px;
  }
}
</style>