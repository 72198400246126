<template>
  <ul :class="classObject">
    <li class="social__item" v-for="item in content" :key="item">
      <a class="social__link" :href="item.link" v-html="item.icon"></a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["parentClass", "content"],
  data() {
    return {
      classObject: {
        [this.parentClass]: this.parentClass,
        social: true,
      },
    };
  },
};
</script>

<style>
.social {
  display: flex;
  align-items: center;
}

.social__item {
  margin-right: 22px;
}

.social__item:last-child {
  margin-right: 0;
}

.social__item svg {
  width: 30px;
  height: 30px;
  display: block;
}

.social__item rect {
  transition: fill 0.4s ease;
}

.social__item:hover rect {
  fill: #e4e4e4;
}

@media (max-width: 1599.98px) {
  .social__item {
    margin-right: 20px;
  }

  .social__item svg {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 1199.98px) {
  .social__item {
    margin-right: 15px;
  }

  .social__item svg {
    width: 20px;
    height: 20px;
  }
}
</style>