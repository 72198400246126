import { createApp } from 'vue'
import App from './App.vue'
import Store from '@/store/index.js'
import VueScrollTo from 'vue-scrollto'
import mediaWp from '@/plugins/mediaWp.js'
import scrollMenu from '@/directives/scrollMenu.js'
import inputMask from '@/directives/inputMask.js'
import '@/assets/css/st-global-v1.css'
import 'aos/dist/aos.css'

createApp(App)
  .use(Store)
  .use(VueScrollTo)
  .use(mediaWp)
  .directive('scroll-menu', scrollMenu)
  .directive('input-mask', inputMask)
  .mount('#app')

