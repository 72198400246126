<template>
  <button :class="classObject">
    <slot />
  </button>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        [this.parentClass]: this.parentClass,
        ["button-link"]: true,
      },
    };
  },
};
</script>

<style>
.button-link {
  position: relative;

  display: block;
  padding: 0;
  border: none;

  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #101010;

  background-color: transparent;

  appearance: none;
  cursor: pointer;
}

.button-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 1px;

  background-color: #000000;
  transition: width 0.3s ease-in;
}

.button-link:hover::after {
  width: 0;
}

.button-link_type_size_min:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;

    width: 0;
    height: 1px;

    background-color: #000000;
    transition: width 0.3s ease-in;
}

.button-link:hover::after {
  width: 100%;
}

@media (max-width: 1599.98px) {
  .button-link {
    font-size: 12px;
  }
}

@media (max-width: 991.98px) {
  .button-link {
    font-size: 10px;
  }
}
</style>