<template>
  <li :class="classObject">
    <div class="card-reviews__meta">
      <div class="card-reviews__rating">
        <span
          class="card-reviews__rating-elem"
          v-for="(item, index) in 5"
          :key="item"
        >
          <svg
            :class="{
              ['card-reviews__rating-icon']: true,
              ['card-reviews__rating-icon_color']: index < content.rating,
            }"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"
              fill="#C4C4C4"
            />
          </svg>
        </span>
      </div>
      <div class="card-reviews__date" v-if="content.date">
        {{ content.date }}
      </div>
    </div>
    <div class="card-reviews__text" v-if="content.feedback">
      {{ content.feedback.slice(0, numberCharacters) }}
      <span v-if="numberCharacters < this.content.feedback.length">...
      <ButtonLink parent-class="button-link_type_size_min card-reviews__button" v-on:click="fullReviews()"
        >читать еще</ButtonLink>
      </span>
    </div>
    <div class="card-reviews__author" v-if="content.author">
      {{ content.author }}
    </div>
  </li>
</template>

<script>
import ButtonLink from "@/components/element/ButtonLinkApp.vue";

export default {
  components: {
    ButtonLink,
  },
  props: ["parentClass", "swiper", "content"],
  data() {
    return {
      classObject: {
        [this.parentClass]: this.parentClass,
        ["swiper-slide"]: this.swiper,
        ["card-reviews"]: true,
      },
      numberCharacters: 250
    };
  },
  methods: {
    fullReviews() {
      this.numberCharacters = this.content.feedback.length;
    }
  }
};
</script>

<style>
.card-reviews {
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-height: 317px;

  background-color: #e9eaf6;
}

.card-reviews__meta {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.card-reviews__rating {
  display: flex;
  flex-grow: 1;
}

.card-reviews__rating-elem {
  margin-right: 10px;
}

.card-reviews__rating-elem:last-child {
  margin-right: 0;
}

.card-reviews__rating-icon_color path {
  fill: #3C43ED;
}

.card-reviews__date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #929292;
}

.card-reviews__text {
  margin-bottom: 25px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #686868;
}

.card-reviews__author {
  margin-top: auto;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #101010;
}

.card-reviews__button {
  display: inline-block;
}

@media (max-width: 1599.98px) {
  .card-reviews {
    min-height: 270px;
    padding: 30px 25px 25px 25px;
  }

  .card-reviews__rating-elem {
    margin-right: 5px;
  }

  .card-reviews__rating-icon {
    width: 15px;
    height: 15px;
  }

  .card-reviews__date {
    font-size: 12px;
  }

  .card-reviews__text {
    margin-bottom: 20px;

    font-size: 12px;
  }

  .card-reviews__button {
    font-size: 12px;
  }

  .card-reviews__author {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .card-reviews {
    min-height: 240px;
    padding: 30px 20px 20px 20px;
  }

  .card-reviews__meta {
    margin-bottom: 12px;
  }

  .card-reviews__rating-elem {
    margin-right: 5px;
  }

  .card-reviews__rating-icon {
    display: block;
    width: 13px;
    height: 13px;
  }
}

@media (max-width: 991.98px) {
  .card-reviews {
    padding: 25px 15px;
  }

  .card-reviews__meta {
    margin-bottom: 19px;
  }

  .card-reviews__date {
    font-size: 10px;
  }

  .card-reviews__text {
    margin-bottom: 20px;

    font-size: 12px;
  }
}
</style>