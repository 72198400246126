<template>
  <div id="map" class="map"></div>
</template>

<script>
import ymaps from "ymaps";

export default {
  mounted() {
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1/?apikey=aebe51a2-52a1-4bd7-a8e1-9ca0b07b7ecd&lang=ru_RU"
      )
      .then((maps) => {
        const map = new maps.Map("map", {
          center: [60.0192080443299,30.268286950144137],
          controls: [],
          zoom: 12,
        });

        // Метка
        let myPlacemark = new maps.Placemark(
          [60.02023913068264,30.225714928659755],
          {},
          {
            iconLayout: "default#image",
            iconImageHref: require('@/assets/img/map.svg'),
            iconImageSize: [50, 66],
            iconImageOffset: [-25, -66],
          }
        );

        // Добавление метки на карту
        map.geoObjects.add(myPlacemark);
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

[class*="ymaps-2"][class*="-copyrights-promo"] {
  display: none;
}

@media (max-width: 532.98px) {
  .map {
  display: none;
}
}
</style>